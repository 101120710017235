<template>
  <div>
    <div style="display: none">
      <div ref="pdf" style="width: 100%">
        <div class="d-flex justify-content-center">
          <div style="width: 197mm; max-height: 500px; margin-top: 20px">
            <b-row>
              <b-col class="pr-0" md="8">
                <b-card no-body class="bank-ticket">
                  <b-row class="card-body-custom">
                    <b-col class="d-flex flex-column align-items-start">
                      <strong class="strong">{{
                        unity.commercial_name
                      }}</strong>
                      <strong class="strong">{{ unity.address }}</strong>
                      <strong class="strong">{{ unity.email }}</strong>
                    </b-col>
                  </b-row>
                </b-card>

                <b-card no-body class="bank-ticket mt-2">
                  <b-row class="card-title-custom">
                    <b-col class="d-flex flex-column align-items-start">
                      <span class="">Pagador</span>
                    </b-col>
                  </b-row>
                  <b-row class="card-body-custom">
                    <b-col class="d-flex flex-column align-items-start">
                      <strong class="strong">
                        {{ financialClient.financial_name }}</strong
                      >
                      <strong class="strong">
                        {{ financialClient.financial_cpf }}</strong
                      >
                      <strong class="strong">
                        {{ financialClientAddress.address }},
                        {{ financialClientAddress.number }} -
                        {{ financialClientAddress.district }} -
                        {{ financialClientAddress.city }} -
                        {{ financialClientAddress.state }}</strong
                      >
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <div>
                  <div>
                    <img
                      src="@/assets/colmeia.png"
                      class="thumbnail my-2"
                      width="200px"
                      alt=""
                    />
                  </div>
                  <div>
                    <b-row>
                      <b-col class="d-flex">
                        <span class="small"
                          >Conheça nossa solução de gestão educacional e
                          esportiva!</span
                        >
                        <img
                          src="@/assets/qr.png"
                          class="thumbnail my-2"
                          width="51px"
                          alt=""
                        />
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="pr-0" md="8">
                <b-card no-body class="bank-ticket h-100">
                  <b-row class="card-title-custom">
                    <b-col class="d-flex flex-column align-items-start">
                      <span class="">Items</span>
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="item in items"
                    :key="item.id"
                    class="card-body-custom"
                  >
                    <b-col class="d-flex justify-content-between">
                      <strong class="strong"> {{ item.description }}</strong>
                      <strong class="strong">
                        {{ toMonetary(item.value) }}</strong
                      >
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col class="pl-2">
                <b-card no-body class="bank-ticket">
                  <b-row>
                    <b-col class="d-flex flex-column align-items-start">
                      <span class="card-body-custom">Vencimento</span>
                      <strong class="strong"> {{ vencimentDay }}</strong>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card no-body class="bank-ticket mt-2">
                  <b-row>
                    <b-col class="d-flex flex-column align-items-start">
                      <span class="card-body-custom">Total</span>
                      <strong class="strong">
                        {{ toMonetary(invoice.total) }}</strong
                      >
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>

            <hr class="dashed" />
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div style="width: 190mm; margin-top: 10px">
            <component :is="bank" />
          </div>
        </div>
      </div>
    </div>

    <SweetModal ref="pdfBoleto" overlay-theme="dark" width="100%">
      <iframe
        :src="`${pdfBase64}`"
        frameborder="0"
        style="width: 100%; height: 80vh"
      ></iframe>
    </SweetModal>   
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
export default {
  data() {
    return {
      bank:'',
      pdfBase64: ""     
    };
  },
  computed: {
    financialClient() {
      return this.$store.state.pdf.clientData.financialClient;
    },
    financialClientAddress() {
      let address = {
        address: "",
      };
      try {
        address = JSON.parse(this.financialClient.address);
      } catch (e) {
        address = this.financialClient.address;
      }
      return address;
    },
    invoice() {
      return this.$store.state.pdf.clientData.invoice[0];
    },
    vencimentDay() {
      return moment(this.invoice.venciment_day)
        .add("hours", "3")
        .format("DD/MM/YYYY");
    },
    documentDate() {
      return moment(this.invoice.created_at)
        .add("hours", "3")
        .format("DD/MM/YYYY");
    },
    unity() {
      return this.$store.state.pdf.clientData.unity[0];
    },
    items() {
      return this.$store.state.pdf.clientData.items;
    },
  },
  methods: {
    to_pdf() {
      this.$emit("freezeButton");
      const opt = {
        filename: "document.pdf",
        name: "teste",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 700,
          letterRendering: true,
          useCORS: true,
          scale: 4,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "p" },
      };
      const self = this;
      return new Promise((res, rej) => {
        html2pdf()
          .set(opt)
          .from(this.$refs.pdf)
          .toPdf()
          .output("datauristring")
          .then(function (pdfAsString) {
            self.pdfBase64 = pdfAsString;
            self.$refs.pdfBoleto.open();
            setTimeout(() => {
              res(false);
            }, 1000);
          });
      }).then((r) => {
        return r;
      });
    },
  },
  components: {
    Bradesco: () => import("./TicketBradesco.vue"),
    Inter: () => import("./TicketInter.vue"),
  },
  mounted(){
    const unityData = this.$store.state.pdf.unityData[0]
    switch (unityData.bank_number) {
      case "077":
        this.bank = "Inter";
        break;
    }
  }
};
</script>

<style lang="scss" scoped>
hr.dashed {
  border-top: 1px dashed black;
}
.bank-ticket {
  background-color: rgb(235, 235, 235);
  padding: 2px 10px;
}
.card-title-custom {
  font-size: 7px;
}
.card-body-custom {
  font-size: 10px;
}
</style>
